/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    h3: "h3",
    ol: "ol",
    li: "li",
    ul: "ul",
    h4: "h4",
    h5: "h5"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-9-mirage-maintenance"
  }, "Day 9: Mirage Maintenance"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2023/day/9"
  }, "https://adventofcode.com/2023/day/9")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2023/solutions/src/day_09.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "You got out of the sandstorm and arrive at an oasis."), "\n", React.createElement(_components.p, null, "You gather some data on the oasis by tracking some datapoints, that’s today’s input."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "0 3 6 9 12 15\n1 3 6 10 15 21\n10 13 16 21 30 45\n")), "\n", React.createElement(_components.p, null, "Each line in the report contains the history of a single value"), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "You want to predict the next value for each datapoint."), "\n", React.createElement(_components.p, null, "The question asks what the sum is of all next values."), "\n", React.createElement(_components.p, null, "To extrapolate the next value of a line, you start by making a new sequence with the difference at each step.\nYou do this until all numbers in the new sequence are zeroes."), "\n", React.createElement(_components.p, null, "In the example input, for the first line:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "0   3   6   9  12  15\n  3   3   3   3   3\n    0   0   0   0\n")), "\n", React.createElement(_components.p, null, "Now to find the next value in the original sequence, start by adding a zero to the end of the last line."), "\n", React.createElement(_components.p, null, "This lets you figure out the last value on every previous line, until you get to the very first line (Nicky’s note: I smell recursion)."), "\n", React.createElement(_components.p, null, "Also, I recognize this from math class! ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Pascal%27s_triangle"
  }, "Pascal’s triangle"), ".\nI don’t remember if there is a clever way to determine a specific value though, so I’m going to code up a brute-force solution and see how that works out.\n(spoiler: it worked out)"), "\n", React.createElement(_components.h3, {
    id: "option-1-imperatively"
  }, "Option 1: imperatively"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "I gather each line into a list of numbers."), "\n", React.createElement(_components.li, null, "For each line, I figure out what the next number would be."), "\n", React.createElement(_components.li, null, "I sum those numbers."), "\n"), "\n", React.createElement(_components.p, null, "It’s the second step that’s the interesting part."), "\n", React.createElement(_components.p, null, "It’s in an infinite loop."), "\n", React.createElement(_components.p, null, "In that loop I build the new sequence of differences from a starting sequence (it starts as line in the input)."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If all numbers in that new sequence are 0, I’m done and break out of the loop."), "\n", React.createElement(_components.li, null, "If not, I set the starting sequence to the sequence of differences, and do it all again!"), "\n"), "\n", React.createElement(_components.p, null, "I keep track of all numbers at the end of a sequence while looping.\nThe next number in a sequence is the sum of its last number + all last numbers in sequences it generates."), "\n", React.createElement(_components.p, null, "To demonstrate, the example for line 3 in the example input:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "10  13  16  21  30  45  68\n   3   3   5   9  15  23\n     0   2   4   6   8\n       2   2   2   2\n         0   0   0\n")), "\n", React.createElement(_components.p, null, "Pointing your attention to the last number on every line.\nAnd checking what I’ve said above: “The next number in a sequence is the sum of its last number + all last numbers in sequences it generates.”"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "68 = 45 + 15 + 6 + 2"), "\n", React.createElement(_components.li, null, "23 = 15 + 6 + 2"), "\n", React.createElement(_components.li, null, "8 = 6 + 2"), "\n", React.createElement(_components.li, null, "2 = 2 + 0"), "\n", React.createElement(_components.li, null, "0 = 0"), "\n"), "\n", React.createElement(_components.h4, {
    id: "code"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "pub fn part_1(input: &str) -> i32 {\n    input\n        .lines()\n        .map(|line| {\n            let mut nums: Vec<i32> = line\n                .split_whitespace()\n                .filter_map(|s| s.parse().ok())\n                .collect();\n\n            let mut edge: Vec<i32> = Vec::new();\n            loop {\n                let differences: Vec<i32> = nums\n                    .iter()\n                    .tuple_windows()\n                    .map(|(left, right)| right - left)\n                    .collect();\n\n                edge.push(*nums.last().unwrap());\n\n                if differences.iter().all(|&x| x == 0) {\n                    let sum: i32 = edge.iter().sum();\n                    break sum;\n                } else {\n                    // prepare for next loop\n                    nums = differences;\n                }\n            }\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h4, {
    id: "bonus-reuse-memory"
  }, "Bonus: reuse memory"), "\n", React.createElement(_components.p, null, "The same code, but keeping some variables around and reusing them.\nAnd indexing into arrays.\nThe result is slightly faster code that uses less memory."), "\n", React.createElement(_components.h5, {
    id: "code-1"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "pub fn part_1(input: &str) -> i32 {\n    let mut nums: Vec<i32> = Vec::new();\n    let mut differences: Vec<i32> = Vec::new();\n    let mut edge: Vec<i32> = Vec::new();\n\n    input\n        .lines()\n        .map(|line| {\n            nums.clear();\n            for num in line.split_ascii_whitespace() {\n                nums.push(num.parse().unwrap());\n            }\n\n            edge.clear();\n\n            loop {\n                differences.clear();\n                for i in nums.windows(2) {\n                    differences.push(i[1] - i[0]);\n                }\n                edge.push(nums[nums.len() - 1]);\n                if differences.iter().all(|&x| x == 0) {\n                    let sum: i32 = edge.iter().sum();\n                    break sum;\n                }\n                std::mem::swap(&mut nums, &mut differences);\n            }\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-recursion"
  }, "Option 2: recursion"), "\n", React.createElement(_components.p, null, "The solution I wrote first, shame it’s significantly slower than the imperative versions, because it reads so nice."), "\n", React.createElement(_components.h4, {
    id: "helpers"
  }, "Helpers"), "\n", React.createElement(_components.p, null, "A function that takes a line of numbers as a string, and turns it into a vector of integers:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn nums(s: &str) -> Vec<i32> {\n    s.split_whitespace()\n        .filter_map(|s| s.parse().ok())\n        .collect()\n}\n")), "\n", React.createElement(_components.p, null, "A function that returns a list of all differences in a sequence:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use itertools::Itertools;\n\nfn differences(nums: &[i32]) -> Vec<i32> {\n    nums.iter()\n        .tuple_windows()\n        .map(|(left, right)| right - left)\n        .collect()\n}\n")), "\n", React.createElement(_components.p, null, "The interesting part, the recursive part!\nA function that returns the next number in a sequence."), "\n", React.createElement(_components.p, null, "If all numbers in the original sequence are 0, the next number is 0 and I break.\nThis is the base case that stops the recursion."), "\n", React.createElement(_components.p, null, "Otherwise, I calculate the list of differences.\nThe next number is the next number in that list of differences plus the last number in the sequence that was passed in."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn next_num(nums: &[i32]) -> i32 {\n    if nums.iter().all(|&n| n == 0) {\n        return 0;\n    }\n    let differences: Vec<i32> = differences(nums);\n    next_num(&differences) + nums.iter().last().unwrap()\n}\n")), "\n", React.createElement(_components.h4, {
    id: "code-2"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "use itertools::Itertools;\n\nfn differences(nums: &[i32]) -> Vec<i32> {\n    nums.iter()\n        .tuple_windows()\n        .map(|(left, right)| right - left)\n        .collect()\n}\n\nfn next_num(nums: &[i32]) -> i32 {\n    if nums.iter().all(|&n| n == 0) {\n        return 0;\n    }\n    let differences: Vec<i32> = differences(nums);\n    next_num(&differences) + nums.iter().last().unwrap()\n}\n\npub fn part_1(input: &str) -> i32 {\n    input.lines().map(|line| next_num(&nums(line))).sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "If we can figure out the next number in a sequence, how about the previous number?"), "\n", React.createElement(_components.p, null, "The question asks what the sum is of all previous values."), "\n", React.createElement(_components.p, null, "Some copy pasting, some minor changes, and voila!"), "\n", React.createElement(_components.p, null, "Now a previous number is the difference between a first number and the previous number from the next line."), "\n", React.createElement(_components.p, null, "The third line in the example again, with special attention to the first number of each line:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt"
  }, "5  10  13  16  21  30  45\n  5   3   3   5   9  15\n   -2   0   2   4   6\n      2   2   2   2\n        0   0   0\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "5 = 10 - 5"), "\n", React.createElement(_components.li, null, "5 = 3 - -2"), "\n", React.createElement(_components.li, null, "-2 = 0 - 2"), "\n", React.createElement(_components.li, null, "0 = 0"), "\n"), "\n", React.createElement(_components.h3, {
    id: "option-1-imperative"
  }, "Option 1: imperative"), "\n", React.createElement(_components.p, null, "I only coded the version that reuses memory, the other one is trivial to make from this one."), "\n", React.createElement(_components.h4, {
    id: "code-3"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "pub fn part_1(input: &str) -> i32 {\n    let mut nums: Vec<i32> = Vec::new();\n    let mut differences: Vec<i32> = Vec::new();\n    let mut edge: Vec<i32> = Vec::new();\n\n    input\n        .lines()\n        .map(|line| {\n            nums.clear();\n            for num in line.split_ascii_whitespace() {\n                nums.push(num.parse().unwrap());\n            }\n\n            edge.clear();\n\n            loop {\n                differences.clear();\n                for i in nums.windows(2) {\n                    differences.push(i[1] - i[0]);\n                }\n                edge.push(nums[nums.len() - 1]);\n                if differences.iter().all(|&x| x == 0) {\n                    let sum: i32 = edge.iter().sum();\n                    break sum;\n                }\n                std::mem::swap(&mut nums, &mut differences);\n            }\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-recursive"
  }, "Option 2: recursive"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The ", React.createElement(_components.code, null, "nums"), " helper gets reused."), "\n", React.createElement(_components.li, null, "The ", React.createElement(_components.code, null, "differences"), " helper gets reused."), "\n", React.createElement(_components.li, null, "The ", React.createElement(_components.code, null, "next_num"), " helper gets replaced by a ", React.createElement(_components.code, null, "prev_num"), " one."), "\n"), "\n", React.createElement(_components.h4, {
    id: "code-4"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs"
  }, "use itertools::Itertools;\n\nfn nums(s: &str) -> Vec<i32> {\n    s.split_whitespace()\n        .filter_map(|s| s.parse().ok())\n        .collect()\n}\n\nfn prev_num(nums: &[i32]) -> i32 {\n    if nums.iter().all(|&n| n == 0) {\n        return 0;\n    }\n    let differences = differences(nums);\n    nums[0] - prev_num(&differences)\n}\n\npub fn part_2(input: &str) -> i32 {\n    input.lines().map(|line| prev_num(&nums(line))).sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.h3, {
    id: "imperative"
  }, "Imperative"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs",
    numberLines: true
  }, "pub fn part_1(input: &str) -> i32 {\n    let mut nums: Vec<i32> = Vec::new();\n    let mut differences: Vec<i32> = Vec::new();\n    let mut edge: Vec<i32> = Vec::new();\n\n    input\n        .lines()\n        .map(|line| {\n            nums.clear();\n            for num in line.split_ascii_whitespace() {\n                nums.push(num.parse().unwrap());\n            }\n\n            edge.clear();\n\n            loop {\n                differences.clear();\n                for i in nums.windows(2) {\n                    differences.push(i[1] - i[0]);\n                }\n                edge.push(nums[nums.len() - 1]);\n                if differences.iter().all(|&x| x == 0) {\n                    let sum: i32 = edge.iter().sum();\n                    break sum;\n                }\n                std::mem::swap(&mut nums, &mut differences);\n            }\n        })\n        .sum()\n}\n\npub fn part_2(input: &str) -> i32 {\n    let mut nums: Vec<i32> = Vec::new();\n    let mut differences: Vec<i32> = Vec::new();\n    let mut edge: Vec<i32> = Vec::new();\n\n    input\n        .lines()\n        .map(|line| {\n            nums.clear();\n            for num in line.split_ascii_whitespace() {\n                nums.push(num.parse().unwrap());\n            }\n\n            edge.clear();\n\n            loop {\n                differences.clear();\n                for i in nums.windows(2) {\n                    differences.push(i[0] - i[1]);\n                }\n                edge.push(nums[0]);\n                if differences.iter().all(|&x| x == 0) {\n                    let sum: i32 = edge.iter().sum();\n                    break sum;\n                }\n                std::mem::swap(&mut nums, &mut differences);\n            }\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "recursion"
  }, "Recursion"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_09.rs",
    numberLines: true
  }, "use itertools::Itertools;\n\nfn nums(s: &str) -> Vec<i32> {\n    s.split_whitespace()\n        .filter_map(|s| s.parse().ok())\n        .collect()\n}\n\nfn differences(nums: &[i32]) -> Vec<i32> {\n    nums.iter()\n        .tuple_windows()\n        .map(|(left, right)| right - left)\n        .collect()\n}\n\nfn next_num(nums: &[i32]) -> i32 {\n    if nums.iter().all(|&n| n == 0) {\n        return 0;\n    }\n    let differences: Vec<i32> = differences(nums);\n    next_num(&differences) + nums.iter().last().unwrap()\n}\n\npub fn part_1(input: &str) -> i32 {\n    input.lines().map(|line| next_num(&nums(line))).sum()\n}\n\nfn prev_num(nums: &[i32]) -> i32 {\n    if nums.iter().all(|&n| n == 0) {\n        return 0;\n    }\n    let differences = differences(nums);\n    nums[0] - prev_num(&differences)\n}\n\npub fn part_2(input: &str) -> i32 {\n    input.lines().map(|line| prev_num(&nums(line))).sum()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
